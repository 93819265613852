import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';

export default (el, props) => {
    const $el = $(el);
    const $inner = $('[data-popup-inner]');
    const $closeBtn = $el.find('[data-popup-close]');
    const $submitBtn = $el.find('[data-newsletter-popup-submit]');

    const onCloseClick = e => {
        e.preventDefault();
        
        localStorage.setItem('newsletterPopupTime', Date.now());
        
        gsap.to(el, {
            duration: 0.2,
            opacity: 0,
            ease: 'sine.out',
            onComplete: () => {
                $el.css({ display: 'none' });
            }
        });
    };

    const onSubmitClick = () => {
        localStorage.setItem('newsletterPopupTime', Date.now());
    };

    const init = () => {
        const now = Date.now();
        const popupShownTime = localStorage.getItem('newsletterPopupTime');

        if ((popupShownTime === null) || (now - popupShownTime > 90 * 24 * 60 * 60 * 1000)) {
            setTimeout(() => {
                $el.css({ display: '' });
                gsap.set($inner.get(0), { opacity: 0 });
                gsap.set(el, { opacity: 0 });

                gsap.to(el, {
                    duration: 0.4,
                    opacity: 1,
                    ease: 'sine.out'
                });

                gsap.to($inner.get(0), {
                    duration: 0.4,
                    delay: 0.3,
                    opacity: 1,
                    ease: 'sine.out'
                });

            }, 10000);
        }

        $closeBtn.on('click', onCloseClick);
        $submitBtn.on('click', onSubmitClick);
    };

    const destroy = () => {
        $closeBtn.off('click', onCloseClick);
        $submitBtn.off('click', onSubmitClick);
    };

    return {
        init,
        destroy
    };
};
