import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Flickity from 'flickity';

export default el => {
    const $el = $(el);
    const $wrapper = $el.find('[data-carousel-wrapper]');
    const $buttonWrapper = $el.find('[data-button-wrapper]');
    const $buttons = $buttonWrapper.find('button');
    const $imgs = $wrapper.find('[data-image]');
    
    let flkty = null;

    const prev = () => {
        if (flkty) {
            flkty.previous();
        }
    };
    
    const next = () => {
        if (flkty) {
            flkty.next();
        }
    };
    
    const updateButtons = () => {
        $buttons.css({ opacity: 1 }).attr('disabled', null);
        
        if (flkty.selectedIndex === 0) {
            $buttons.filter('.previous').css({ opacity: 0 }).attr('disabled', '');
        }
        
        if (flkty.selectedIndex >= flkty.slides.length - 1) {
            $buttons.filter('.next').css({ opacity: 0 }).attr('disabled', '');
        }
    };
    
    const onResize = () => {
        if ($imgs.length > 0) {
            $buttons.css({ top: ($($imgs.get(0)).height() + $($buttons.get(0)).height()) / 2 });
        } else {
            $buttons.css({ top: ($($wrapper.find('.flickity-viewport').get(0)).height() + $($buttons.get(0)).height()) / 2 });
        }
    };
    
    const init = () => {
        const element = $wrapper.length ? $wrapper.get(0) : el;
        flkty = new Flickity(element, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            wrapAround: false,
            freeScroll: true,
            freeScrollFriction: 0.045
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });
        
        flkty.on('change', index => {
            updateButtons();
        });
        
        flkty.on('select', index => {
            updateButtons();
        });
        
        $buttonWrapper.removeClass('hidden');
        
        $buttons.on('click', e => {
            const $button = $(e.triggerTarget);

            if ($button.hasClass('previous')) {
                prev();
            } else if ($button.hasClass('next')) {
                next();
            }
        });
        
        onResize();
        updateButtons();
        
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        if (flkty) {
            flkty.destroy();
        }
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
